import { SettingsMenuEnum } from 'modules/profile/enums/settings-menu-enum'

const settingsMenuHref: Record<SettingsMenuEnum, string> = {
  [SettingsMenuEnum.profile]: '/profile/user-settings',
  [SettingsMenuEnum.account]: '/profile/account-settings',
  [SettingsMenuEnum.my_plan]: '/profile/user-plan',
  [SettingsMenuEnum.payment_gateways]: '/profile/payment-gateways',
  [SettingsMenuEnum.manage_subscriptions]: '/profile/manage-subscriptions',
  [SettingsMenuEnum.mailing]: '/profile/mailing-settings',
  [SettingsMenuEnum.membership]: '/profile/course-settings',
  [SettingsMenuEnum.payment_page]: '/profile/payment-settings',
  [SettingsMenuEnum.sales_funnel]: '/profile/sales-funnels-settings',
  [SettingsMenuEnum.shipping]: '/profile/shipping-settings',
  [SettingsMenuEnum.affiliate_program]: '/profile/affiliate-program-settings',
  [SettingsMenuEnum.custom_domains]: '/profile/custom-domains-settings',
  [SettingsMenuEnum.public_api]: '/profile/public-api-settings',
  [SettingsMenuEnum.webhooks]: '/profile/webhook-settings',
  [SettingsMenuEnum.integrations]: '/profile/integrations',
  [SettingsMenuEnum.affiliate_commissions]: '/profile/affiliate-settings',
  [SettingsMenuEnum.notification]: '/profile/notification-settings',
  [SettingsMenuEnum.workspace_members]: '/profile/workspace-members',
  [SettingsMenuEnum.change_password]: '/profile/change-password',
}

export const settingsMenuWildcards: Partial<Record<SettingsMenuEnum, string>> = {
  [SettingsMenuEnum.webhooks]: '/profile/webhook-settings/.*',
}

export const customerSettingsMenuHref: Partial<Record<SettingsMenuEnum, string>> = {
  ...settingsMenuHref,
}

export const affiliateSettingsMenuHref: Partial<Record<SettingsMenuEnum, string>> = {
  [SettingsMenuEnum.profile]: settingsMenuHref.profile,
  [SettingsMenuEnum.affiliate_commissions]: settingsMenuHref.affiliate_commissions,
  [SettingsMenuEnum.notification]: settingsMenuHref.notification,
  [SettingsMenuEnum.change_password]: settingsMenuHref.change_password,
}

export const affiliateStudentSettingsMenuHref: Partial<Record<SettingsMenuEnum, string>> = {
  [SettingsMenuEnum.profile]: settingsMenuHref.profile,
  [SettingsMenuEnum.account]: settingsMenuHref.account,
  [SettingsMenuEnum.manage_subscriptions]: settingsMenuHref.manage_subscriptions,
  [SettingsMenuEnum.affiliate_commissions]: settingsMenuHref.affiliate_commissions,
  [SettingsMenuEnum.notification]: settingsMenuHref.notification,
  [SettingsMenuEnum.change_password]: settingsMenuHref.change_password,
}

export const workspaceAssistantSettingsMenuHref: Partial<Record<SettingsMenuEnum, string>> = {
  [SettingsMenuEnum.account]: settingsMenuHref.account,
  [SettingsMenuEnum.mailing]: settingsMenuHref.mailing,
  [SettingsMenuEnum.membership]: settingsMenuHref.membership,
  [SettingsMenuEnum.sales_funnel]: settingsMenuHref.sales_funnel,
  [SettingsMenuEnum.notification]: settingsMenuHref.notification,
}

export const workspaceAdminSettingsMenuHref: Partial<Record<SettingsMenuEnum, string>> = {
  [SettingsMenuEnum.account]: settingsMenuHref.account,
  [SettingsMenuEnum.mailing]: settingsMenuHref.mailing,
  [SettingsMenuEnum.membership]: settingsMenuHref.membership,
  [SettingsMenuEnum.payment_page]: settingsMenuHref.payment_page,
  [SettingsMenuEnum.sales_funnel]: settingsMenuHref.sales_funnel,
  [SettingsMenuEnum.public_api]: settingsMenuHref.public_api,
  [SettingsMenuEnum.notification]: settingsMenuHref.notification,
  [SettingsMenuEnum.webhooks]: settingsMenuHref.webhooks,
  [SettingsMenuEnum.workspace_members]: settingsMenuHref.workspace_members,
  [SettingsMenuEnum.integrations]: settingsMenuHref.integrations,
}
