import { SettingsMenuEnum } from 'modules/profile/enums/settings-menu-enum'

export const settingsMenuLocoKeys: Record<SettingsMenuEnum, string> = {
  [SettingsMenuEnum.profile]: 'dashboard.user_settings.profile.title',
  [SettingsMenuEnum.account]: 'dashboard.user_settings.account.title',
  [SettingsMenuEnum.my_plan]: 'dashboard.user_settings.my_plan.title',
  [SettingsMenuEnum.shipping]: 'dashboard.user_settings.shipping.title',
  [SettingsMenuEnum.payment_gateways]: 'dashboard.user_settings.payment_gateways.title',
  [SettingsMenuEnum.manage_subscriptions]: 'dashboard.user_settings.manage_subscriptions.title',
  [SettingsMenuEnum.mailing]: 'dashboard.user_settings.mailing.title',
  [SettingsMenuEnum.membership]: 'dashboard.user_settings.membership',
  [SettingsMenuEnum.payment_page]: 'dashboard.user_settings.payment_page.title',
  [SettingsMenuEnum.sales_funnel]: 'dashboard.user_settings.sales_funnel.title',
  [SettingsMenuEnum.affiliate_program]: 'dashboard.user_settings.affiliate_program.title',
  [SettingsMenuEnum.custom_domains]: 'dashboard.user_settings.custom_domains.title',
  [SettingsMenuEnum.public_api]: 'dashboard.user_settings.public_api.title_plural',
  [SettingsMenuEnum.webhooks]: 'dashboard.user_settings.webhooks.title_plural',
  [SettingsMenuEnum.affiliate_commissions]: 'dashboard.user_settings.affiliate_commissions.title',
  [SettingsMenuEnum.notification]: 'dashboard.user_settings.notification.title',
  [SettingsMenuEnum.integrations]: 'dashboard.user_settings.integrations.title',
  [SettingsMenuEnum.workspace_members]: 'dashboard.user_settings.workspace_members.title',
  [SettingsMenuEnum.change_password]: 'dashboard.user_settings.change_password.title',
}
